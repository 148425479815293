.header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .chat-options {
        display: flex;
        flex-direction: row;
        margin-left: auto;

        button {
            background-color: #6c6c7f;
            color: white;
            border-radius: 5px;
            margin: 0 3px;
            padding: 8px;

            &:disabled {
                color: #50505E;
            }

            svg {
                font-size: 20px;
            }
        }
    }
}

.description {
    label {
        color: #F1F2F4;
    }
}

hr {
    border: 1px solid #1F1D2B;
    margin: 0;
}

.vertical-line {
    background-color: #6c6c7f;
    width: 2px;
    height: 85%;
    margin: 0 7px;
    border-radius: 1px;
    align-self: center;
}

.header-container {
    background: #252836;
    position: sticky;
    z-index: 2;

    .header {
        padding: 15px
    }

    .description {
        padding: 0 15px 15px;
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;

    .editButton {
        margin-right: 5px;
        padding: 4px;
        border-radius: 5px;
        height: 28px;

        &.confirm {
            background-color: #CDFEDB;
            color: #47B505;
        }

        &.cancel {
            background-color: #FEDFCD;
            color: #FF2D2D;
        }

        svg {
            font-size: 18px;
        }

    }
}

.new-transcription {
    padding: 20px;
    height: 100%;
    background-color: #2E3038;

    .header {
        display: flex;

        >* {
            width: 100%;
        }

        h3 {
            color: #F1F2F4;
            margin: 0;
            font-size: 18px;
            font-style: normal;
        }

    }

    label {
        color: #ABAEB0;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .MuiInputBase-root {
        border: 1px solid #808191;
        border-radius: 10px;
        background-color: #3A3D47;

        input,
        .MuiSvgIcon-root {
            color: #ABAEB0;
        }
    }

    .MuiInputBase-input {
        color: #ABAEB0;
        padding: 10px;
    }

    .time {
        display: flex;
        flex-direction: column;
    }

    .MuiGrid-item {
        padding: 5px;
    }
}

.add-new-button {
    position: sticky;
    bottom: 0;
    height: 30px;
    width: 100%;

    button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 30px;
        background-color: #6C6C7E;
        color: white;
        border: 0;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        font-family: sans-serif;
        display: flex;

        &:hover {
            background-color: #6C6C7E;
        }

        svg {
            margin-right: 5px;
        }
    }
}

.speakers-modal {
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 5px;
        overflow: auto;
        background: #1F1D2B;
        border-radius: 10px;
        padding: 35px 50px;
        color: #fff;
        width: 740px;

        .field-wrap {
            display: flex;
            justify-content: space-around;
            margin-bottom: 10px;

            label {
                color: white;
            }
        }
    }
}

.chat-container {
    position: relative;
}

.search {
    padding: 20px;
    height: 100%;
    background-color: #2E3038;
    width: 100%;

    .search-header {
        display: flex;
        padding: 5px;

        >* {
            width: 100%;
        }

        h3 {
            color: #F1F2F4;
            margin: 0;
            font-style: normal;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    label {
        color: #ABAEB0;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .search-actions {
        display: flex;
        padding-left: 10px;

        button {
            padding: 0;
            color: #ABAEB0;
        }

    }

    .search-container {
        display: flex;

        .default-button {
            height: 80%;
            text-transform: uppercase;
            font-size: 0.9em;
        }

        .MuiInput-underline:after,
        .MuiInput-underline:before {
            border-bottom: 0 !important;
        }

        .MuiInputBase-adornedEnd button {
            margin-right: 5px;
        }

        >:first-child {
            width: calc(100% - 84px);
        }

        .MuiInputBase-root {
            border: 1px solid #808191;
            border-radius: 10px;
            background-color: #3A3D47;

            input,
            .MuiSvgIcon-root {
                color: #ABAEB0;
            }
        }

        .MuiInputBase-input {
            color: #ABAEB0;
            padding: 10px;
        }
    }
}

.default-button {
    padding: 0px 8px;
    background: transparent;
    border: 0;
    font-size: 1em;
    background-color: #6c6c7f;
    color: white;
    border-radius: 5px;
    margin-left: 9px;
    cursor: pointer;
}

.export-options {
    background-color: #6c6c7f !important;
    border: 1px solid #1F1D2B;

    a {
        color: white;
        text-decoration: none;
        justify-content: center;
    }

    button {
        font-family: SF Pro Text, sans-serif !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        font-size: 1em;
        padding: 0;
    }
}

.mobile-side-panel .settings-container .header {
    flex-direction: column;

    .chat-options {
        justify-content: start;
        margin-left: unset;
    }
}

.download-menu {
    background-color: #6c6c7f;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
    box-shadow: rgba(0,0,0,0.2) 0px 3px 1px -2px, rgba(0,0,0,0.14) 0px 2px 2px 0px, rgba(0,0,0,0.12) 0px 1px 5px 0px;

    a {
        border-bottom: 1px solid rgba(0,0,0,0.3);
        padding: 8px 0;
    }

    button {
        margin: 8px 0;
        border: 0;
        cursor: pointer;
    }

    a,
    button {
        width: 100%;
        text-align: center;
        background-color: transparent;
        color: white;
        text-decoration: underline;
        font-family: SF Pro Text, sans-serif;
        font-size: 1em;
    }
}

.download-arrow {
    width: 100%;
    height: 5px;
    display: flex;
    justify-content: center;

    div {
        background-color: #6c6c7f;
        box-shadow: rgba(0,0,0,0.2) 1px 3px 3px 3px, rgba(0,0,0,0.14) 0px 2px 2px 0px, rgba(0,0,0,0.12) 0px 1px 5px 0px;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        z-index: -1;
    }
}

.chat-container {
    font-size: 17px;
    line-height: 20px;
    background: #252836;
}