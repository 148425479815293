.app-main {
    .project {
        &-card {
            background-color: #252836;
            color: #808191;
            overflow: unset;
        }

        &-img {
            height: 200px;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-content {
            padding: 16px !important;

            @media screen and (max-width: 1399px) {
                padding: 10px !important;
            }
        }

        &-name {
            font-size: 17px;
            font-weight: 700;
            color: #fff;
            word-break: break-all;

            @media screen and (max-width: 1399px) {
                font-size: 14px;
            }
        }

        &-details {
            margin-bottom: 20px;
            color: var(--fontColor1);

            @media screen and (max-width: 1399px) {
                margin-bottom: 10px;
            }
        }

        &-updated {
            font-size: 12px;
            font-weight: 500;
        }

        &-status {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: var(--fontColor1);

            p {
                margin-right: 5px;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &-detect {
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: var(--fontColor1);
        }

        &-eyeicn {
            margin-right: 8px;
            width: 15px;
            height: 15px;
        }

        &-editpreicn {
            background: #353340;
            border-radius: 3px;
            width: 30px;
            height: 30px;
            color: #7E86FF
        }

        &-deleteicn {
            background: #353340;
            border-radius: 3px;
            width: 30px;
            height: 30px;
            color: #FF2D2D
        }

        &-play {
            position: absolute;
            color: white;
            border-radius: 25%;
            border-color: 1px solid #000;
        }

        &-play:hover {
            background-color: rgba(0, 0, 0, 0.54);
            transition: background-color 1s;
        }

        &-select-folder {
            background-color: #808191;
            color: #ABAEB0;
            border: 1px solid #ABAEB0;
            border-radius: 5px;
        }
    }
}

.move-folder-dialog {
    .MuiDialog-paper {
        width: 400px;
    }
    .select-folder {
        display: flex;
        flex-direction: column;

        span {
            .project-name {
                color: #6C5ECF;
            }

            margin-bottom: 10px;
        }
    }
    
    .project-select-folder {
        background-color: #3A3D47;
        color: #ABAEB0;
        border: 1px solid #ABAEB0;
        border-radius: 10px;

        .MuiSelect-select {
            padding: 10px 32px 10px 18px;
        }

        .MuiSvgIcon-root {
            color: #ABAEB0;
        }
    }
}

.MuiList-root {
    .MuiMenuItem-root {
        padding-left: 20px;
    }
}