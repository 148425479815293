.authenticate {
  width: 500px;
  margin: auto;

  h2 {
    color: white;
  }

  img {
    width: 100%;
    margin: 50px 0;
  }

  label {
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    margin: 10px 0;
  }

  input {
    background-color: hsla(0, 0%, 76.9%, 0.05);
    color: #ffffffcc;
  }

  button.signin {
    background-color: #6c5ecf;
    width: 100%;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 55px;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    color: #cfcfcf;
    text-transform: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    border: 0;
    margin: 15px 0px !important;
  }
}