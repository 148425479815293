.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto 1fr;
  grid-gap: 10px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.video-preview {
  grid-column: span 3;
  grid-row: span 3;
  padding: 10px;

  .video-js {
    width: 100% !important;
  }
}

.input-thumbnail {
  grid-column: span 3;
  height: fit-content;
  grid-row: span 1;
  display: flex;

  #upload-project-thumbnail {
    top: 5px;
    margin: 20px 20px 15px 0px;
    height: 35px;
    max-width: 250px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    text-transform: none;
    background-color: #6c6c7f;
  }
}

.selected {
  border-radius: 10px;
  border: 2px solid #010102;
}

.thumbnail-container {
  position: relative;
  margin: 5px 10px 5px 0;
  width: auto;
  height: auto;
  max-height: 150px !important;
  & .overlay {
    opacity: 1;
  }

  .thumbnail-selected {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    transition: 0.5s ease;
    border-radius: 10px;
    border: 2px solid #6c5ecf;
  }
  .thumbnail-selected:before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    transform: translate(-50%, -50%);
    color: #6c5ecf;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  .thumbnail-item {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1920px) {
    width: 380px;
  }

  @media screen and (max-width: 1893px) {
    width: 370px;
  }

  @media screen and (max-width: 1853px) {
    width: 320px;
  }

  @media screen and (max-width: 1653px) {
    width: 310px;
  }

  @media screen and (max-width: 1613px) {
    width: 300px;
  }

  @media screen and (max-width: 1573px) {
    width: 280px;
  }

  @media screen and (max-width: 1343px) {
    width: 300px;
    max-height: 100px !important;
  }

  @media screen and (max-width: 991px) {
    width: 300px;
  }

  @media screen and (max-width: 575px) {
    width: 300px;
  }
}
.thumbnailLoader {
  color: #6c5ecf;
}
