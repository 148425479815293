@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#custom-menu {
  z-index: 1000;
  position: absolute;
  background-color: #252836;
  border-radius: 5px;
  padding: 10px;
  box-shadow: -1px -1px 20px 0px rgb(102, 102, 102);
  width: 18em;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  list-style: none;
  margin: 0;

  hr {
    border: none;
    height: 0.1px;
    background: #808191;
  }
  button {
    display: block;
    padding: 5px;
    background: transparent;
    border: 0;
    outline: 0;
    width: 100%;
    text-align: left;
    color: #808191;
    margin-bottom: 2px;

    span {
      display: inline;
      position: absolute;
      right: 5px;
      text-align: right;
    }

    &:hover {
      border-radius: 5px;
      background-color: #1f1d2b;
      cursor: pointer;
    }
  }
}
