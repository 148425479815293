@font-face {
  font-family: "SF Pro Text";
  src: url("assets/fonts/SFProText-Regular.woff2") format("woff2"),
    url("assets/fonts/SFProText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("assets/fonts/SFProText-Bold.woff2") format("woff2"),
    url("assets/fonts/SFProText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --fontColor1: #c4c4c4;
  --iconColorFill: #ffffffcc;
  --itemHoverColor: #ffffff0f;
  --backColor: #2E3038;
  --backColorBlack: #1F1D2B;
}
