.redact-preview-container{
    display: flex;

    .preview-transcription{
        background-color: #252836;
        flex-grow: 1;
        height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 60px;
        min-width: 365px;

        @media screen and (min-width: 991px) {
            width: 400px;
            height: 631px;
          }
        
          @media screen and (max-width: 990px) {
            width: 250px;
            height: 331px;
          }
        
          @media screen and (max-width: 576px) {
            width: 200px;
            height: 231px;
          }
    }
}

.chat {
    .row {
        &.left {
            * {
                flex-direction: row;
            }
        }

        &.right {

            &,
            * {
                flex-direction: row-reverse;
            }

            .time {
                display: flex;
                justify-content: flex-start;
            }

            .transcript .sentence {
                display: inline-flex;

                .text {

                    &.editing textarea {
                        text-align: end;
                    }
                }
            }
        }
    }
}

.settings-container .header {
    flex-direction: column;

    .chat-options {
        justify-content: start;
        margin-left: unset;
    }
}

.chat-container {
    position: relative;
    font-size: 17px;
    line-height: 20px;
    background: #252836;
}