.select-workspace {
    .MuiSelect-select {
        padding-top: 9px;
        padding-bottom: 9px;
        background-color: #c4c4c40d;
        color: var(--fontColor1);
        border-radius: 3px;
        position: relative;
        border: 1px solid rgba(207, 207, 207, 0.05);
        
    }
    .MuiOutlinedInput-notchedOutline{
        border: none;
    }
    svg {
        fill: white;
    }
}