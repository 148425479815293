.myTable {
  .MuiTable-root {
    border-spacing: 0 5px;
    border-collapse: separate;
  }

  .MuiPaper-root {
    background: #2e3038;
    border-radius: 20px;
    padding: 20px;
  }

  .MuiTableCell-root {
    color: #fff;
    border: 0;

    .Mui-active {
      color: #fff !important;
    }

    .MuiTableSortLabel-icon {
      color: #fff !important;
    }
  }

  .MuiTableCell-head {
    font-size: 20px;
    border-bottom: 1px solid #808191;
    background: #2e3038;

  }

  .MuiTableHead-root {
    margin-bottom: 10px;
  }

  .table-avatar {
    display: flex;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 3px;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background: rgba(31, 29, 43, 0.25);

      &:hover {
        background: rgba(107, 94, 207, 0.25);
        cursor: pointer;
      }
    }
  }

  .MuiTablePagination-root {
    color: #fff;
  }

  .MuiSvgIcon-root {
    opacity: 0.5;
    color: #fff;
  }

  .memberRoles {
    padding: 4px 18px;
    color: #efae06;
    background: #fef8e6;
    border-radius: 10px;
    display: inline-flex;
  }

  .adminRoles {
    padding: 4px 18px;
    color: #06DB41;
    background: #CDFEDB;
    border-radius: 10px;
    display: inline-flex;
  }

  .btn {
    background: #1f1d2b;
    border-radius: 6px;
    margin: 0 4px;
  }

  .MuiTablePagination-select {
    background: #1f1d2b;
    border-radius: 6px;
  }

  .MuiTablePagination-actions .MuiButtonBase-root {
    border-radius: 6px;
    padding: 2.5px;
    margin-right: 10px;

    &:not(.Mui-disabled) {
      background: #1f1d2b;
    }
  }
}