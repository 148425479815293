$base-color: hsl(0, 0%, 77%);
$main-color: #6C5ECF;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #1F1D2B !important;
  color: #808191 !important;
}

/*App Layout*/
.app-page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app-main {
  height: 100%;
  flex: 1;
  overflow: auto;
  scrollbar-color: #6c5ecf #252836;
  scrollbar-width: thin;
  .MuiSlider-root {
    margin: 20px 30px;
    width: 94%;
    color: $main-color;
    @media screen and (max-width: 992px) {
      width: 92%
    }
    @media screen and (max-width: 576px) {
      width: 82%
    }
  }
  .MuiSlider-markLabel {
    color: $base-color;
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }
  }
}
.project-checkbox {
  .MuiIconButton-root {
      padding-top: 0;
      padding-bottom: 0;
  }
  .MuiSvgIcon-root {
    height: 2.8rem;
  }
  .MuiFormControlLabel-label {
    font-size: 16px;
    color: #cfcfcf;
    font-weight: 500;
  }
  .MuiCheckbox-root:hover{
    background-color:transparent  !important;
  }
  .chk-box-color, .Mui-checked{
    color: #6C5ECF !important
  }
  .Mui-disabled {
    color: #cfcfcf80 !important;
  }
}
.project-label.Mui-disabled {
  color: #cfcfcf80 !important;
}
.project-description{
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(196, 196, 196, 0.05);
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(196, 196, 196, 0.05) !important;
    border-width: 1px !important;
    transition: 0.3s all;
    border-radius: 3px !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $main-color !important;
  } 
  .MuiInputBase-root {
    margin-top: 25px;
    padding: 10px 15px;
    background-color: rgba(196, 196, 196, 0.05);
    color: var(--fontColor1);
    font-size: 1rem;
    border: 1px solid rgba(207, 207, 207, 0.05);
    margin-bottom:10px;
    &:hover .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
    &.Mui-focused {
        border-color: #6C5ECF;
    }
    &:focus-visible {
        outline: none;
    }
  }
}
 
/*App Layout END*/

.page-title {
 color: #fff; 
 font-size: 30px;
 font-weight: 700;
 margin: 0;
 @media screen and (max-width: 1450px) {
  font-size: 25px;
 }
  @media screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 38px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #6c5ecf;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 20px -10px 8px 1px #6c5ecf;
}
