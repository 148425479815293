.delete-dialog {
    .MuiDialog-paper {
        background: var(--backColorBlack);
        border-radius: 10px;
        color: #FFFFFF;
        padding-left: 4px;

        .delete-dialog-title {
            font-size: 24px;
        }

        .delete-dialog-title-notes {
            color: #FFFFFF;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .detect-alert-dialog-ask {
            margin-top: 10px;
            .MuiCheckbox-root {
                color: #6C5ECF;

                &.Mui-checked {
                    color: #6C5ECF;
                }
            }
        }

        .MuiDialogActions-root {
            margin-right: auto;
            padding-left: 16px;
            margin-bottom: 15px;
        }

        .delete-button {
            background: #6C5ECF;
            border-radius: 6px;
            width: 89px;
            height: 32px;
            color: #FFFFFF;
            font-size: 14px;
            text-transform: capitalize;
        }

        .cancel-button {
            background: #FF2D2D;
            border-radius: 6px;
            width: 89px;
            height: 32px;
            color: #FFFFFF;
            font-size: 14px;
            text-transform: capitalize;
        }
    }

}