.app-page-inner {
  padding: 15px;
  width: 100%;

  .project-button {
    max-width: 150px;
    background-color: #6C5ECF;
    margin-left: 10px;

    &:hover {
      background-color: #5044a3
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .project-addIcon {
    fill: #6C5ECF;
    margin-top: 5px;

    @media screen and (min-width: 769px) {
      display: none;

    }
  }

  .css-3n8c3j-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #abaeb0;
    box-shadow: none;
    background-color: #6c6c7f;
}

.css-cknbk0-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #808191;
}

  .project {
    &-search {
      &-wrapper {
        position: relative;
        max-width: 690px;
        width: 100%;

        &-workspace {
          width: unset;
          position: relative;
        }

        @media screen and (max-width: 992px) {
          width: 400px;
        }

        @media screen and (max-width: 576px) {
          width: 175px;
          margin-bottom: 12px;
        }
      }

      background: #252836;
      border-radius: 10px;
      color: var(--fontColor1);
      width: 100%;
      padding: 5px 17px;

      @media screen and (max-width: 1399px) {
        padding: 8px 40px 8px 18px;
      }

      @media screen and (max-width: 575px) {
        padding: 5px 40px 5px 15px;
        font-size: 14px;
      }

      font-size: 16px;
    }

    &-search-icn {
      position: absolute;
      top: 50%;
      z-index: 1;
      right: 10px;
      transform: translateY(-50%);

      @media screen and (max-width: 575px) {
        right: 5px;
      }
    }

    &-icon {
      color: #808191;
    }

    &-footer {
      li {
        .Mui-selected{
          background: #252836;
        }
      }
    }

    &-pagination {
      margin-top: 20px;
      display: flex;

      ul {
        margin: auto;
      }

      ul li button,
      div {
        color: white;
      }

      ul>li:first-child {
        button {
          visibility: hidden;
        }

        button {
          &:before {
            content: 'First';
            visibility: visible;
            color: white;
          }
        }
      }

      ul>li:last-child {
        button {
          visibility: hidden;
        }

        button {
          &:before {
            content: 'Last';
            visibility: visible;
            color: white;
          }
        }
      }
    }
  }

  .MuiTablePagination-root {
    color: #fff;
    border-bottom: none;

    .MuiSvgIcon-root {
      opacity: 0.5;
      color: #fff;
    }
  }
}

.folder-project-container {
  display: flex;
  flex-direction: row;
}

.project-list {

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media screen and (max-width: 1250px) {
      justify-content: center;
    }
    grid-gap: 22px;
    -ms-grid-row-gap: 22px;
    -ms-grid-column-gap: 22px;
    grid-row-gap: 22px;
    grid-column-gap: 22px;
    width: 100%;

    .project-card {
      width: 400px;

      @media screen and (max-width: 1920px) {
        width: 380px;
      }

      @media screen and (max-width: 1893px) {
        width: 370px;
      }

      @media screen and (max-width: 1853px) {
        width: 320px;
      }

      @media screen and (max-width: 1653px) {
        width: 310px;
      }

      @media screen and (max-width: 1613px) {
        width: 300px;
      }

      @media screen and (max-width: 1573px) {
        width: 280px;
      }

      @media screen and (max-width: 1343px) {
        width: 300px;
      }

      @media screen and (max-width: 991px) {
        width: 300px;
      }

      @media screen and (max-width: 575px) {
        width: 300px;
      }
    }

  }
}

.project-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #6C5ECF;
  padding: 5px;

  .video-js {
    .vjs-control-bar {
      bottom: -32px;
    }
  }

  .vjs-fullscreen {
    .vjs-control-bar {
      bottom: 0px;
    }
  }
}
