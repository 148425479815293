.video-js {
    .vjs-control-bar {
        z-index: 1;
        opacity: 1 !important;
        pointer-events: auto !important;
        bottom: -43px;
        background-color: #252836;
       .vjs-current-time, .vjs-no-flex .vjs-current-time {
            display: block ;
        }
        .vjs-remaining-time {
        display: block ;
    }
    }

    .vjs-big-play-button {
        z-index: 1;
    }

    .vjs-menu-button {
        top: 10px;
    }

    & .vjs-seek-button {
        font-family: "VideoJS";
        cursor: pointer;
        font-weight: 400;
        font-style: normal;
    }
    &.vjs-v6,
    &.vjs-v7 {
        & .vjs-seek-button {
            &.skip-back::before,
            &.skip-forward::before {
                content: none;
            }
            &.skip-back .vjs-icon-placeholder::before {
                content: "\f120";
            }
            &.skip-forward .vjs-icon-placeholder::before {
                content: "\f11f";
            }
        }
    }
}

