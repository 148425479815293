.chat {
    .row {
        &.left {
            * {
                flex-direction: row;
            }
        }

        &.right {

            &,
            * {
                flex-direction: row-reverse;
            }

            .time {
                display: flex;
                justify-content: flex-start;
            }

            .transcript .sentence {
                display: inline-flex;

                .text {

                    &.editing textarea {
                        text-align: end;
                    }
                }
            }
        }
    }
}

.basic {
    padding: 0 15px;
    cursor: pointer;
}

.basic-text {
    padding: 0;

    &.current {
        border-bottom: 2px solid white;
    }
}

.row {
    color: #E3E9EC;
    display: flex;
    margin: 10px 0;


    &.left,
    &.right {
        padding: 0 15px;
    }

    .transcript {
        margin: 0 15px;

        .time {
            display: flex;
            font-size: 12px;
            font-style: italic;
            margin: 5px;
        }

        .sentence {
            align-items: center;
            display: flex;

            .text {
                border-radius: 20px;
                padding: 6px 14px;
                background-color: #6C6C7E;

                &:not(.editing) {
                    cursor: pointer;
                }

                &.editing {
                    background-color: #1F1D2B;
                    width: 50%;

                    .MuiFormControl-root {
                        width: 100%;

                        .MuiInputBase-root {
                            color: white;
                            width: 100%;
                        }
                    }
                }

                .MuiInputBase-root {
                    color: white;
                    padding: 0;

                    .MuiOutlinedInput-notchedOutline {
                        border: 0;
                    }
                }
            }

            &.current {
                .text {
                    background-color: #F1F2F4;

                    .MuiInputBase-root,
                    span {
                        color: #252836;
                    }
                }
            }

            .actions {
                margin: 0 5px;
                display: flex;
                width: 85px;
                flex: 1;

                button {
                    background: #1F1D2B;
                    padding: 3px;
                    border-radius: 3px;
                    margin-right: 3px;
                    position: unset;
                }

                svg {
                    font-size: 17px;
                }
            }
        }
    }


    .transcript,
    .sentence {
        width: 100%;
    }
}

.basic {
    .speaker {
        display: none !important;
    }

    .transcript {
        margin: 0;
    }
}

.speaker {
    display: inline-flex;
    align-items: flex-start;
}

.speaker-bubble {
    font-family: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #252836;
    border: 0;
    font-size: 1em;

    &.small {
        width: 30px;
        height: 30px;
        font-size: 0.7em;
    }
}

.chat .row.right,
.chat .row.right {
    .popper-box * {
        flex-direction: row;
    }
}


.popper-box {
    &[data-popper-placement='top-start']>.arrow {
        bottom: -2px;
        left: 15px;
    
        &::before {
            border-color: #6C6C7E transparent transparent;
            border-width: 8px 8px 0 8px;
        }
    }

    &[data-popper-placement='top-end']>.arrow {
        bottom: -2px;
        right: 30px;

        &::before {
            border-color: #6C6C7E transparent transparent;
            border-width: 8px 8px 0 8px;
        }
    }
    &[data-popper-placement='bottom-start']>.arrow {
        top: -9px;
        left: 15px;
    
        &::before {
            border-color: transparent transparent #6C6C7E;
            border-width: 0 8px 8px;
        }
    }
    &[data-popper-placement='bottom-end']>.arrow {
        top: -9px;
        right: 29px;
    
        &::before {
            border-color: transparent transparent #6C6C7E;
            border-width: 0 8px 8px;
        }
    }

    &>.arrow,
    &>.arrow::before {
        content: "";
        margin: auto;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        position: absolute;
    }

    &>.arrow {
        visibility: hidden;
    }

    &>.arrow::before {
        visibility: visible;
        content: '';
    }


    .content {
        border: 2px solid #6C6C7E;
        padding: 5px;
        border-radius: 5px;
        background-color: #1F1D2B;

        >* {
            display: flex;
            align-items: center;
            font-size: 0.9em;

            &:not(.MuiInputBase-root)>* {
                margin: 5px;
            }
        }

        svg {
            font-size: 20px;

            &.current {
                color: #6AFB93;
            }
        }

        .add-speakers-input, .edit-speaker input {
            margin: 0;
            border-radius: 5px 0 0 5px;
            height: 3em;
            padding: 10px;
            background-color: #3A3D47;
            border: 0.5px solid #808191;
            color: #ABAEB0;
            outline: 0;
        }

        .add-speakers-button {
            background: #6C6C7E;
            border-radius: 5px;
            color: white;
            margin: 0;
            padding: 9px;
            border: 0;
            border-radius: 0 5px 5px 0;
            cursor: pointer;
        }

        .edit-speaker input {
            border-radius: 5px;
            height: 1em;
        }
    }
}