.control-button-wrap .MuiButton-root {

    min-width: 5px;

    @media screen and (min-width: 991px) and (max-width: 1104px) {
        width: 50px;
    }
}

.small {
    width: 100%;
}

.gutter {
    background-color: #2E3038;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 100%;
    min-width: 20px;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}