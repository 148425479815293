$base-color: hsl(0, 0%, 77%);
$main-color: #6C5ECF;

.create-header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
   .left-wrap {
    display: flex;
    align-items: center;

    .icon-wrapper {
        cursor: pointer;

        svg {
            color: white;
            margin-right: 10px;
        }
    }
}
  .MuiTypography-gutterBottom{
    color: #fff;
    font-weight: 600;
    @media screen and (max-width: 576px) {
       font-size: 20px;
    }
  }
  .MuiButton-root.btn {
    color: $base-color;
    text-transform: none;
    &-submit {
      background-color: #6c5ecf;
      &:hover {
        background-color: #5e4fcb;
      }
    }

    &-cancel {
      background-color: #ff4040;
      &:hover {
        background-color: #ed4646;
      }
    }
  }

  .btn-fixed-top{
    position: fixed;
    right: 28px;
    top: 12px;
    z-index: 1;
  }
}
.create-project {
  background-color: #252836;
  padding: 20px;
  border-radius: 20px;
  
  .form-item {
    @media screen and (min-width: 991px) {
      width: 50%;
    }
  }
  .MuiFormLabel-root.project-label {
    color: var(--fontColor1);
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 2rem;
    display: block;
    top: -5px;
    span {
      color: red;
      font-size: 14px;
    } 
    &-small {
      top: 16px;
      color: var(--fontColor1);
      font-size: 22px;
      font-weight: 600;
    }
  }
  .radio-buttons {
    display: flex;
    flex-direction: row;
  }
  .no-preview {
    color: var(--fontColor1);
    font-size: 16px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: $main-color !important;
  }
  .project-input .MuiInputBase-input {
    background-color: rgba($base-color, 0.05);
    color: var(--fontColor1);
    padding: 16px 23px;
  }
  .send-code-btn {
    margin-top: 36px !important;
  }
  .MuiOutlinedInput-multiline {
    background-color: rgba($base-color, 0.05);
    padding: 16px 23px;
    margin-top: 24px;
    .MuiInputBase-input {
      color: var(--fontColor1);
      padding: 5px 0;
    }
  }
  .project-description {
    margin-bottom: 0 !important;
  }
  .MuiFormControl-root {
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .MuiFormControlLabel-root {
    font-size: 2rem;
  }
  .input-thumbnail {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .preview-image{
      height: 114px;
      width: 195px;
      border-radius: 10px;
    }
  }
  .choose-file {
    border: 1px solid rgba($base-color, 0.1);
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    background-color: rgba(196, 196, 196, 0.05);
    color: var(--fontColor1);
  }
  .error-text {
    color: red;
  }
  .error-message{
    margin-top: 10px;
    background-color: red;
    color: white;
    svg{
      fill: white;
    }
  }
}
