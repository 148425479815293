.workspace-table-row {
    border-bottom: 1px solid #00000030;
    & .noBorderCell {
      border-bottom: none;
      color: white;
      padding: 0;
    }
  
    & .actionCell {
      border-bottom: none;
      text-align: right;
      padding: 0;
    }
  }