.over-style{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background:#0a0909de;
  color: #808191 !important;
  z-index: 99;
}
.loading-msg{
  font-size: 25px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
.app-loader {
    position: absolute;
    width: 80px;
    height: 80px;
    margin-bottom: 150px;
    justify-content: inherit;
  }
  .app-loader div {
    transform-origin: 40px 40px;
    animation: app-loader 1.2s linear infinite;
  }
  .app-loader div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #6C5ECF;
  }
  .app-loader div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .app-loader div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .app-loader div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .app-loader div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .app-loader div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .app-loader div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .app-loader div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .app-loader div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .app-loader div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .app-loader div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .app-loader div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .app-loader div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes app-loader {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }