.detect-alert-dialog {
  .MuiDialog-paper{
      background-color:#252836;
      color:#cfcfcf;
  }
  .MuiDialogTitle-root{
      margin-bottom:20px;
      color:#6C5ECF;
      font-weight: bold;
   }
  .MuiDialogContentText-root {
      margin-bottom: 20px;
      color:#cfcfcf
  }
  .MuiCheckbox-root{
    color:#6C5ECF !important;
  }
  .MuiFormControlLabel-label {
      color: #6C5ECF
  }
  .MuiButton-root{
    background-color:#6C5ECF;
    color:white;
    width: 110px;
    padding: 8px;
    &:hover{
        background-color:#5044a3;
    }
  }
}