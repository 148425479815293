.form-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    h3 {
        color: white;
        margin: 0;
    }

    .buttons {
        margin-left: auto;
    }
}

.description {
    label {
        color: #F1F2F4;
    }
}

hr {
    border: 1px solid #1F1D2B;
    margin: 0;
}

.vertical-line {
    background-color: #6c6c7f;
    width: 2px;
    height: 85%;
    margin: 0 7px;
    border-radius: 1px;
    align-self: center;
}

.MuiMenu-root {
    .MuiMenuItem-root {
        display: block;

        span {
            padding: 0 15px;
            line-height: 2.2em;
        }
    }

    &.MuiMenu-list {
        padding-top: 0;
    }
}

.transcription-form {
    padding: 20px;
    height: 100%;
    background-color: #2E3038;
    width: 100%;

    .popper-box {
        z-index: 1;
    }

    .header {
        display: flex;

        >* {
            width: 100%;
        }

        h3 {
            color: #F1F2F4;
            margin: 0;
            font-size: 18px;
            font-style: normal;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;

            .editButton {
                margin-right: 5px;
                padding: 4px;
                border-radius: 5px;
                height: 28px;

                &.redact {
                    background-color: #6c6c7f;
                    color: white;
                }

                &.confirm {
                    background-color: #CDFEDB;
                    color: #47B505;
                }

                &.cancel {
                    background-color: #FEDFCD;
                    color: #FF2D2D;
                }

                svg {
                    font-size: 18px;
                }

            }
        }
    }

    label {
        color: #ABAEB0;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .MuiInputBase-root {
        border: 1px solid #808191;
        border-radius: 10px;
        background-color: #3A3D47;

        input,
        .MuiSvgIcon-root {
            color: #ABAEB0;
        }
    }

    .MuiInputBase-input {
        color: #ABAEB0;
    }

    .MuiInputBase-input:not(.MuiInputBase-inputMultiline),
    .MuiInputBase-multiline {
        padding: 10px;
    }

    .time {
        display: flex;
        flex-direction: column;

        .MuiFormControl-root {
            height: 46px;
            display: inline-block;

            .MuiInputBase-input {
                background: none;
                border: 0;
                letter-spacing: 5px;
                padding: 8px 10px;
                font-size: 0.8em;

                &:focus-visible {
                    outline: none;
                }
            }

            .description {
                display: block;
                position: absolute;
                color: #808191;
                top: 26px;
                font-size: 11px;
                padding: 0 10px;
                width: 100%;
                letter-spacing: 5px;
            }
        }
    }

    .MuiGrid-item {
        padding: 5px;
    }
}

.add-new-button {
    position: sticky;
    bottom: 0;
    height: 30px;
    width: 100%;

    button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 30px;
        background-color: #6C6C7E;
        color: white;
        border: 0;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        font-family: sans-serif;
        display: flex;

        &:hover {
            background-color: #6C6C7E;
        }

        svg {
            margin-right: 5px;
        }
    }
}

.add-speakers-input {
    margin: 0;
    border-radius: 5px 0 0 5px;
    padding: 10px;
    background-color: #3A3D47;
    border: 0.5px solid #808191;
    color: #ABAEB0;
    outline: 0;
    font-size: 15px;
    height: 3em;
    width: 100%;
}

.add-speakers-button {
    background: #6C6C7E;
    color: white;
    margin: 0;
    padding: 9px;
    border: 0;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 15px;
    height: 3em;
    width: 80px;
}

.content.durations {
    input {
        background-color: #3A3D47;
        border: 1px solid #808191;
        border-radius: 5px;
        color: #ABAEB0;
        height: 2.5em;
        padding: 8px;
        width: 100px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {

        opacity: 1;
        color: blue;
        background-color: green;
        border-color: red;

    }
}