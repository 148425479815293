.confirm-dialog {
  .MuiDialog-paper {
    background: var(--backColorBlack);
    border-radius: 10px;
    color: #ffffff;
    padding-left: 4px;
  }
  .confirm-dialog-title {
    font-size: 24px;
  }

  .confirm-dialog-title-notes {
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .MuiDialogActions-root {
    margin-right: auto;
    padding-left: 16px;
    margin-bottom: 15px;
  }

  .confirm-button {
    background: #6c5ecf;
    border-radius: 6px;
    width: 89px;
    height: 32px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
  }

  .cancel-button {
    background: #ff2d2d;
    border-radius: 6px;
    width: 89px;
    height: 32px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
  }
}
