$base-color: rgb(196, 196, 196);
$main-color: #6c5ecf;

.settings-container {

  h2.title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
    color: #fff;
  }

  label.project-label {
    color: var(--fontColor1);
    font-size: 20px;
    font-weight: 600;
    display: block;
    position: relative;
  }

  .project-description {

    .MuiInputBase-root {
      margin: 0;
    }
  }

  .project-name {
    font-weight: 400;
    margin: 0;

    .MuiInputBase-input {
      background-color: rgba($base-color, 0.05);
      color: var(--fontColor1);
      padding: 10px 15px;
      font-size: 1rem;
      border: 1px solid rgba(207, 207, 207, 0.05);
    }
  }

  .edit-project-thumb {

    .thumbnail-div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .thumbnail-img {
      width: 200px;
      height: auto;
      max-height: 200px;
    }

    .thumbnail-column-div {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

    .thumbnail-link {
      color: #5e4fcb;
      text-decoration: underline;
      font-size: 16px;
      cursor: pointer;
    }

    .thumbnailLoader {
      color: #5e4fcb;
    }

    .input-thumbnail {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .preview-image {
        height: auto;
        max-height: 200px;
        width: 200px;
      }
    }
  }

  .action-button {
    color: #fff;
    min-width: 120px;
  }

  .save {
    background-color: $main-color;

    &:hover {
      background-color: #5e4fcb;
    }
  }

  .cancel {
    background-color: #ff4e49;

    &:hover {
      background-color: #ff4040;
    }
  }

  .radio-buttons {
    display: flex;
    flex-direction: row;
  }
}