.user-modal {
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    overflow: auto;
    background: #1F1D2B;
    border-radius: 10px;
    padding: 35px 50px;
    color: #fff;
    width: 740px;

    .field-wrap {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;

      label {
        color: white;
      }
    }
  }

  .icon-eye {
    position: absolute;
    top: 39px;
    right: 5px;
    width: 18px;
    height: 18px;
  }

  .error-msg {
    color: red;
    margin-top: 5px;
  }

  .create-project {
    background-color: transparent;
    padding: 0;

    label {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
    }

    .project-input {
      .MuiInputBase-input {
        padding: 11px 16px;
        font-size: 14px;
        &.Mui-disabled {
          -webkit-text-fill-color: gray !important;
        }
      }
    }

    .auto-complete-input {

      .MuiFormControl-root {
        background-color: rgba(196, 196, 196, 0.05);
        border: 1px solid rgba(207, 207, 207, 0.05);
        color: var(--fontColor1);

        .MuiInputBase-root {
          padding: 0;
        }

        svg {
          fill: white;
        }
      }

      .MuiInputBase-input {
        padding: 11px 31px 11px 16px;
        font-size: 14px;
        color: var(--fontColor1);
      }
    }

    .MuiSelect-select {
      padding-top: 9px;
      padding-bottom: 9px;
      background-color: rgba(196, 196, 196, 0.05);
      color: var(--fontColor1);
      border-radius: 3px;
      position: relative;
      border: 1px solid rgba(207, 207, 207, 0.05);
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }

  .modal-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 0;
  }
  .modal-subtitle {
    font-weight: 100;
    font-size: 12px;
    margin-top: -20px;
  }


  .select-input {
    margin-top: -5px;

    svg {
      fill: white;
    }
  }

  .MuiButton-root {
    margin: 0 5px !important;
  
    &.btn {
      font-size: 14px;
      border-radius: 6px !important;
      min-width: 94px;
      text-transform: capitalize;
      height: 38px !important;
  
      &-primary {
        background: #6C5ECF;
        color: #fff;
  
        &:hover {
          background: #342e63;
          color: #fff;
        }
      }
  
      &-danger {
        background: #FF2D2D;
        color: #FFFFFF;
  
        &:hover {
          background: #a21d1d;
          color: #FFFFFF;
        }
      }
  
      &-autogenerate {
        margin-top: 35px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding-left: 10px;
        padding-right: 10px;
      }
  
      &-admin-autogenerate {
        font-size: 14px !important;
        font-weight: 400 !important;
        padding-left: 10px;
        padding-right: 10px;
      }
  
      &.Mui-disabled {
        opacity: 0.26;
        color: white;
        background: #6C5ECF;
      }
    }
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .right {
    display: flex;
  }
}

.title-badge {
  font-size: 14px;
  display: inline-flex;
  padding: 3px 10px;
  background: #2e3038;
  border: 1px solid #1f1d2b;
  border-radius: 6px;
  margin-left: 16px;
}

.inline-title {
  display: flex;
  align-items: center;
}

.MuiMenu-paper {
  &.MuiPaper-elevation {
    background: #3A3D47;
    color: #ABAEB0;

    .Mui-selected {
      background: rgba(107, 94, 207, 0.25);
    }
  }
}

.add-button {
  background-color: #6C5ECF;
  margin-left: 10px;
}

.identifiertype-dropdown {
  margin-top: 12px !important; 
}