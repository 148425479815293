.video-thumbnail-selector {
  .text-uppercase {
    text-transform: uppercase;
  }

  .select-thumbnail-button {
    padding: 47px;
    border: 1px dotted #ccc;
  }

  .thumbnail-container {
    position: relative;
    margin: 5px 10px 5px 0;
    & .overlay {
      opacity: 1;
    }

    .thumbnail-selected {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: .8;
      transition: .5s ease;
      border-radius: 10px;
      border: 2px solid #6c5ecf;
    }
    .thumbnail-selected:before{
       content: '\2713';    
        position: absolute;
        top: 50%; left: 50%;
        width: 90px; height: 90px;
        transform: translate(-50%,-50%);
        color: #6c5ecf;
        font-size: 60px;
        line-height: 80px;
        text-align: center;
    }

    .thumbnail-item {
      border-radius: 10px;
      height: 100%;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .thumbnailLoader {
    color: #6c5ecf;
  }

  
}

.input-thumbnail {

  #upload-project-thumbnail {
    margin: 20px 20px 15px 0px;
    height: 35px;
    max-width: 250px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    text-transform: none;
    background-color: #6c6c7f;
  }
}
