// .detected-block-row {
//     display: grid;
//     grid-gap: 15px;
//     grid-template-columns: repeat(4, 1fr);
//     @media screen and (max-width: 767px) {
//         grid-template-columns: repeat(3, 1fr);
//     }
//     @media screen and (max-width: 575px) {
//         grid-template-columns: repeat(2, 1fr);
//     }
//     @media screen and (max-width: 370px) {
//         grid-template-columns: repeat(1, 1fr);
//     }
// }
.detected-block {
    border: 2px solid transparent;
    background-color: #252836 !important;
    color: #808191 !important;
    cursor: pointer;

    &:hover, &-active {
       border-radius: 4px;
       border: 2px solid #6C5ECF;
    }

    .preview-img {
        text-align: center;
        background-color: #fff;
        height: 100px;

        img {
            height: 100%;
        }
    }

    .detail-section {
        padding: 5px 10px;
    }
}
