.edit-project-inner {
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
    }
}

.video-canvas-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    flex-shrink: 0;
    @media screen and (max-width: 1399px) {
        height: calc(100vh - 116px);
    }
    @media screen and (max-width: 575px) {
        height: calc(100vh - 109px);
    }
    @media screen and (max-width: 991px) {
        width: 100%;
    }
    .video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .video-canvas {
        position: relative;
        height: 50%;
        margin-bottom: 50px;
        @media screen and (min-width: 1600px) {
            max-height: 700px !important;
        }
    
        @media screen and (min-width: 1600px) and (max-width: 2319px) {
            max-height: 450px !important;
        }
        @media screen and (max-width: 1599px) and (min-width: 992px) {
            max-height: 380px !important;
        }
        @media screen and (max-width: 991px) {
            max-height: 300px;
        }
        .canvas-container {
            position: absolute !important;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            max-width: 100%;
            z-index: 1;
            canvas {
                width: 100% !important;
                height: 100% !important;
                top: 0;
                left: 0;
                margin: 0 !important;
            }
        }
    }
}

.editor-sidebar {
    flex-grow: 1;
    min-width: 0;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: 1399px) {
        height: calc(100vh - 116px);
    }
    @media screen and (max-width: 575px) {
        height: calc(100vh - 109px);
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 15px;
    }

    .control-section {
        position: sticky;
        top: 0;
    }
}

.video-control {
    background-color: #252836;
    padding: 10px;
    // margin-bottom: 20px;
    .MuiDivider-root {
        margin: 10px 0;
        color: #2E3038;
    }
    .project-header-name {
        font-size: 24px;
        font-weight: 700;
        color: white;
        word-wrap: break-word;
    }

    .control-button-wrap {
        display: flex;
        justify-content: space-around;
        // flex-wrap: wrap;
    }
}
.project-detect-card {
    padding: 10px 15px 15px;
    background-color: #252836;
    color:var(--fontColor1);
    h2 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 20px;
    }

    .preview-img {
        margin-bottom: 15px;
        height: 190px;
        display: flex;
        background: #fff;

        img {
            height: 100%;
            margin: auto;
        }
    }
    .MuiListItemButton-root {
        padding-top: 0;
        padding-bottom: 0;
    }
    .MuiListItemText-root {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .MuiListItemText-primary {
        margin-right: 10px;
        font-size: 16px;
    }
    .MuiListItemText-secondary {
        color: var(--fontColor1);
        font-size: 16px;
        font-weight: 700;
    }
    .detected-color .MuiListItemText-secondary {
        color: #fff;
    }
    .MuiFormLabel-root.project-label {
        color: var(--fontColor1);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 2rem;
        display: block;
        top: -5px;
    }
    .project-input .MuiInputBase-input {
        background-color: rgba(196, 196, 196, 0.05);
        color: var(--fontColor1);
        padding: 10px 15px;
        margin-top: 0;
        font-size: 1rem;
        margin-bottom: 10px;
    }
    .form-wrapper {
        margin-top: 20px;
        .Mui-error{
            input{
                border: 1px solid red;
            }
        }
    }
    &-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}
.video-descreption {
    h3 {
        margin-top: 0;
        margin-bottom: 10px;
    }
    p   {
        margin-top: 0;
    }
}
.detected-color {
    padding: 5px;
    color: #fff;
}
 
.project-objectWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .object-name {
        color:#808191;
        font-size: 18px;
        margin-right: 15px;
    }
}

.duplicate-header{
    color: #808191;
    font-size: 14px;
    font-weight: 700;
    display: block;
    margin-top: 10px;
}

.selection-modal-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:#252836;
    overflow: auto;
    h3{
        margin: 20px;
        background-color: #6C5ECF;
        padding:15px;
        color:white;
    }
}
.project{
    &-auto{
        display: flex;
        align-items: center;
        justify-content:center;    
       }
       &-loader{
        margin-right: 10px;
       }
       &-loadtext{
         color: var(--fontColor1);
         font-size:14px;
       }
}
.object-card-wrap{
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
    max-height:62vh;
}

.errorMessage{
    color: red;
    margin-left: 15px;
}

.split {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 100px);
    width: 100%;

    @media screen and (max-width: 1399px) {
        height: calc(100vh - 116px);
    }
    @media screen and (max-width: 575px) {
        height: calc(100vh - 109px);
    }
    >section {
        overflow-x: hidden;
        overflow-y: auto;

        >div {
            margin: 0 10px;
        }
    }
}