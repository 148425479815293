.admin-wrapper {
  padding: 20px 20px;

  .tab-content {
    margin-top: 20px;

    .tab-list {
      border-bottom: 2px solid #808191;
    }

    .user-wrapper {
      .user-header {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-wrapper {
          display: flex;
          align-items: center;

          .total-wrapper {
            padding: 8px 16px;
            font-size: 14px;
            background: #2e3038;
            border: 1px solid #1f1d2b;
            border-radius: 6px;
            color: #ffffff;
            margin-right: 20px;
          }

          .project {
            &-search {
              &-wrapper {
                position: relative;
                max-width: 250px;

                @media screen and (max-width: 992px) {
                  width: 400px;
                }

                @media screen and (max-width: 576px) {
                  width: 175px;
                  margin-bottom: 12px;
                }
              }

              background: #252836;
              border-radius: 10px;
              color: var(--fontColor1);
              width: 100%;
              padding: 5px 17px;

              @media screen and (max-width: 1399px) {
                padding: 8px 40px 8px 18px;
              }

              @media screen and (max-width: 575px) {
                padding: 5px 40px 5px 15px;
                font-size: 14px;
              }

              font-size: 16px;
            }

            &-search-icn {
              position: absolute;
              top: 50%;
              z-index: 1;
              right: 10px;
              transform: translateY(-50%);

              @media screen and (max-width: 575px) {
                right: 5px;
              }
            }

            &-icon {
              color: #808191;
            }
          }
        }

        .add-button {
          background-color: #6c5ecf;
        }
      }

      .table-wrapper {
        margin-top: 20px;
      }
    }
  }
}

.add-workspace {
  padding: 20px 20px;

  .header {
    display: flex;
    justify-content: space-between;

    .left-wrapper {
      display: flex;
      align-items: center;

      .icon-wrapper {
        cursor: pointer;

        svg {
          color: white;
          margin-right: 10px;
        }
      }
    }

    .add-workspace-button {
      background-color: #808191;
      margin-left: 10px;
    }

    .add-button {
      background-color: #6c5ecf;
      margin-left: 10px;
    }

    .save-button {
      margin-left: 10px;
      background-color: #6c5ecf;
    }

    .setting-button {
      background-color: #808191;
    }
    .active {
      border: #c9c9cf solid 3px;
    background-color: #c9c9cf;
    }
  }

  .add-button {
    background-color: #6c5ecf;
    align-self: flex-start;
  }

  .add-button:hover {
    background-color: #6c5ecf !important;
  }

  .name-input-wrap {
    margin-top: 15px;
    background: #2e3038;
    padding: 15px;
    display: flex;
    flex-direction: column;

    label {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #6c5ecf !important;
    }

    .project-input {
      .MuiInputBase-input {
        padding: 9px 16px;
        font-size: 14px;
        background-color: rgba(196, 196, 196, 0.05);
        color: var(--fontColor1);
        &.Mui-disabled {
          -webkit-text-fill-color: gray !important;
        }
      }
    }

    .settings {
      margin-top: 30px;
    }
  }

  .table-wrapper {
    margin-top: 20px;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;

    .MuiIconButton-edgeEnd {
      background-color: #808191;
      border-radius: 0 5px 5px 0;
      padding: 8px;
      svg {
        color: white;
      }

      &:hover svg {
        color: lightgray;
      }
    }
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0;

    .MuiIconButton-edgeStart {
      background-color: #808191;
      border-radius: 5px 0 0 5px;
      padding: 8px;
      margin: 0;

      svg {
        color: white;
      }

      &:hover svg {
        color: lightgray;
      }
    }
  }
}

.project-checkbox .MuiSvgIcon-root {
  height: auto;
}

.MuiButton-root {
  margin: 0 5px !important;
  &.btn {
    font-size: 14px;
    border-radius: 6px !important;
    min-width: 94px;
    text-transform: capitalize;
    height: 38px !important;

    &-autogenerate {
      margin-top: 35px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.delete-icon {
  margin-left: auto;
  margin-top: 8px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: red;
}
