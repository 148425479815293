$back-color: #252836;
$main-color: #6c5ecf;

.app-page .app-navbar {
  background-color: var(--backColor);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 15px;
  z-index: 999;
  @media screen and (max-width: 1399px) {
    padding: 15px 20px;
  }
  @media screen and (max-width: 575px) {
    padding: 10px 15px 13px 10px;
  }

  @media screen and (max-width: 991px) {
    overflow: hidden;
  }

  .navbar {
    &-icon {
      .MuiSvgIcon-root{
        color: #808191;
        fill: var(--iconColorFill);
      } 
    }
    &-menu {
      @media screen and (min-width: 1251px) {
        display: none;
      }
    }
    &-action {
      &-left {
        button:last-child {
          @media screen and (min-width: 991px) {
            margin-left: 15px !important;
          }
        }
      }
    }
    &-profile {
      padding-left: 10px !important;
      margin-left: 10px !important;
      @media screen and (max-width: 991px) {
        padding-right: 0px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
  .has-notification {
    position: relative;
    color: #808191;
  }
  .has-notification:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid $back-color;
    background-color: #ff1500;
    border-radius: 50%;
    display: block;
    bottom: 50%;
    left: 50%;
  }
}
.profile-menu {
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 11rem
  }
  .MuiListItemText-secondary {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 11rem;
    color: #fff;
  }
  .MuiMenu-paper {
    background-color: $back-color;
    left: 1600px;
    width: 250px;
    top: 85px !important;
  }
  .MuiList-root {
    display: flex;
    flex-direction: column;
    background-color: #1F1D2B;
    color: #fff;
    padding-top: 0px;
    padding-bottom: 0px;
    li {
      padding: 12px 15px;
      justify-content: flex-start;
      &:hover{
        background-color: var(--itemHoverColor);
      }
    }
    .MuiAvatar-circular {
      margin-right: 5px;
    }
    .MuiSvgIcon-root {
      color: $main-color;
    }
  }
}

.logo {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
  & + div {
    height: 100%;
  }

  img {
    max-width: 150px;
    border-radius: 10px;
  }
}

.menu-list {
  .MuiListItem-root{
    .MuiTypography-root {
      color: var(--fontColor1);
      font-size: 17px;
    }
    &:hover{
      background-color: var(--itemHoverColor);
    }
  }
  .MuiSvgIcon-root {
    fill: #6c5ecf;
    margin-left: 5px;
  }
  a {
    color: #808191;
  }
  .active {
    color: #fff;
    .MuiListItem-root {
      background-color: var(--itemHoverColor);
    }
  }
  .icn{
    padding: 5px;
    border-radius: 3px;
    width: 38px;
    height: 38px;
    fill: #6c5ecf;
  }
  .logout{
    position: absolute;
    bottom: 20px;
    cursor: pointer;
  }  
}