$base-color: rgb(196, 196, 196);

.folder-list {
  min-width: 250px;
  max-width: 250px;
  min-height: calc(100vh - 85px);
  font-size: 1.1em;
  font-weight: bold;
  margin-right: 10px;
  color: #808191;
  background-color: var(--backColor);
  overflow-y: auto;

  .add-folder-form {
    background-color: #1F1D2B;
    padding: 12px 12px 16px 12px;
    margin: 5px;
    border-radius: 5px;

    .form-header {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .Mui-disabled {
        opacity: 0.4;
      }

      >.MuiFormLabel-root {
        color: #ABAEB0;
        font-size: 1em;
      }

      .editButton.cancel {
        margin-right: 0;
      }
    }

    .MuiInputBase-input {
      background: #3A3D47;
      border-radius: 10px;
      color: #ABAEB0;
      padding: 8px 10px;
      border: 1px solid #808191;
    }
  }

  .header {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .add-button {
      background-color: #6C6C7E;
      color: white;
      border-radius: 5px;
      padding: 0;
      margin: 0 16px 0 auto;
      height: 30px;
      width: 30px;

      svg {
        font-size: 0.9em;
      }
    }

    h3 {
      margin: 0 16px;
      color: white;
    }
  }

  hr {
    margin: 20px 5px;
  }

  .folder-row {
    padding: 12px 0;

    &.active {
      background-color: rgba(#6C5ECF, 0.2);
      color: white;

      .menu-icon .MuiIconButton-root {
        color: white;
      }
    }

    .folder {
      margin: 0 16px;
      cursor: pointer;

      .folder-icon {
        margin-right: 8px;
        background-color: #1F1D2B;
        border-radius: 5px;
        display: inline-flex;

        svg {
          margin: 8px;
        }
      }

      .menu-icon {
        margin-left: auto;

        .MuiIconButton-root {
          color: #808191;
          padding: 0;
        }
      }

      display: flex;
      align-items: center;
    }
  }
}

.floating-menu .MuiPaper-root {
  color: #808191;
  background-color: var(--backColor);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-radius: 5px;
}

.edit-folder-dialog {
  label.project-label {
    color: $base-color;
    font-size: 20px;
    font-weight: 600;
    display: block;
    position: relative;
  }

  .MuiInputBase-input {
    background-color: rgba($base-color, 0.05);
    color: var(--fontColor1);
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid rgba(207, 207, 207, 0.05);
  }

  .MuiInputBase-root {
    margin-top: 5px;
  }
}

.folder {
  &.folder-dialog {

    .MuiBackdrop-root {
      background-color: rgba(50,50,50,0.5);
    }
    .MuiDialog-paper {
      background: var(--backColorBlack);
      border-radius: 10px;
      color: #FFFFFF;
      padding-left: 4px;

      .dialog-title {
        font-size: 24px;
      }

      .MuiDialogContent-root {
        .MuiDialogContentText-root {
          margin: 0;
          color: $base-color;
          font-size: 1em;
          color: white;
        }

        p {
          font-size: 0.8em;
          color: #BACBD5;
          margin: 10px 0;
        }
      }

      .MuiDialogActions-root {
        padding: 0px 24px 24px 24px;
        border-radius: 6px;
        font-size: 14px;
        margin-right: 0;

        .MuiButtonBase-root {
          &.finish-button {
            background: #6C5ECF;
            color: #FFFFFF;
            text-transform: none;
          }

          &.cancel-button {
            background: #FF2D2D;
            color: #FFFFFF;
          }
        }
      }
    }

  }
}