$main-color: #6c5ecf;
.notification-menu {
  .MuiMenu-paper {
    background-color: #252836;
    left: unset !important;
    width: 350px;
    right: 0;
    top: 85px !important;
  }
  .wrapper {
    overflow: hidden;
    height: 90vh;
    .list {
      overflow-y: auto;
      height: 100%;
    }
    .notification-item {
      display: flex;
      border-bottom: 1px solid #3a3750;
      padding: 5px;
      a {
        color: #c4c4c4;
        text-decoration: none;
        .message {
          display: flex;
          align-items: center;
          p {
            color: white;
          }
          svg {
            margin-right: 5px;
            margin-left: 5px;
          }
        }
        .time {
          p {
            margin: 10px 0px 10px 40px;
            font-size: 12px;
          }
        }
      }

      svg {
        color: $main-color;
        width: 24px;
        height: 24px;
      }
      .delete-icon {
        margin-left: auto;
        margin-top: 8px;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: red;
      }

      &:hover {
        background: var(--itemHoverColor);
      }
    }
    .clear-all {
      background-color: $main-color;
      &:hover {
        background-color: #5e4fcb;
      }
      color: white;
    }
  }
  .empty{
    text-align: center;
    margin: 7px;
    color: white;
  }
}
