.video-js {
  border-radius: 8px;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;

  &.create-preview {
    @media screen and (min-width: 991px) {
      width: 50%;
      height: 450px;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  @media screen and (min-width: 991px) {
    width: 850px;
    height: 600px;
  }

  @media screen and (max-width: 990px) {
    width: 450px;
    height: 300px;
  }

  @media screen and (max-width: 576px) {
    width: 300px;
    height: 200px;
  }
}